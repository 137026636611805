

#SECTION\#DATIAMMINISTRATIVIcollapseControl #ENTI\.CONTATTI\.RECAPITICol,
#SECTION\#DATIAMMINISTRATIVIcollapseControl #COMMESSECol,
#SECTION\#DATIAMMINISTRATIVIcollapseControl #INDIRIZZICol{
    padding: 0px!important;
}

#SECTION\#DATIAMMINISTRATIVIcollapseControl  .card-header,

#SECTION\#DATIAMMINISTRATIVIcollapseControl  .cardBodyTable{
    border: 0px!important;
}
#SECTION\#DATIAMMINISTRATIVIcollapseCard .card-header{
    border: 2px!important;
}

#SECTION\#DATIAMMINISTRATIVIcollapseControl  .card-header{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}
#SECTION\#DATIAMMINISTRATIVIcollapseControl  .card-header h6  svg{
    display: none!important;
}

#SECTION\#SPESE  h3,
#SECTION\#INTERVENTI  h3{
    float: none;
}
#SECTION\#INTERVENTI  .row {
    margin-top: 5px;
}

#INTERVENTIcollapseControl > .card-body
#SPESEcollapseControl > .card-body{
    padding: 1rem!important;
}
#INTERVENTIcollapseControl > .card-body .card-body,
#INTERVENTIcollapseControl > .card-body .card-header,
#INTERVENTIcollapseControl > .card-body .card-accent-info,
#SPESEcollapseControl > .card-body .card-body,
#SPESEcollapseControl > .card-body .card-header,
#SPESEcollapseControl > .card-body .card-accent-info{
    border: 0px!important;
}
#INTERVENTIcollapseControl > .card-body .card-header,
#SPESEcollapseControl > .card-body .card-header{
    background-color: #FAFBFC !important;
}
#INTERVENTIcollapseControl > .card-body .card-body,
#SPESEcollapseControl > .card-body .card-body{
    border-bottom: #bdc3c7 2px solid!important;
    border-radius: 0!important;
}


.btn-primary{
    background-color: #039998!important;
    border-color: #039998!important;
    color: #fff!important;
}
.planning .scrollForm .card-body,
.planning .scrollForm .card-header{
    
    border:1px solid #aabfe8!important
}
.planning .scrollForm .card-body{
    border-radius:  0px 0px 6px 6px!important;
    border-top: 0px!important;
}
.cardForm.planning.card{
    margin-bottom: 0px!important;
    margin-top: 5px;
}
.weekDay span{
  cursor:pointer;
  /* margin-left:5px; */
}
.weekDay .planColumn {
  overflow-y: scroll;
  height: calc(100vh - 206px)!important;
}
.weekDay>.card{
  margin-bottom: 0px;
  height: calc(100vh - 120px)!important;
}
.planning .scrollform{
    height: calc(100vh - 90px);
}