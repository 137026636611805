// Here you can add other styles


table th .form-group,table tr .form-group  {
    margin-bottom: 0rem;
}
table th .col-md-12 .col-md-12{
    padding-right: 0px;
    padding-left: 0px;
}
label{
    font-weight:bold;
}